
import ClickOutside from 'vue-click-outside'
import Field from './Field.vue'
import { formPropsMixin } from '~/mixins'
import { highlight } from '~/utils'
export default {
  components: {
    Field,
  },
  directives: {
    ClickOutside,
  },
  mixins: [formPropsMixin],
  props: {
    options: {
      type: [Array, Object],
      default: () => [],
    },
    searchable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: {
        value: '',
        text: '',
      },
      isListVisible: false,
    }
  },
  computed: {
    formattedList() {
      return this.options.map((item) => {
        if (typeof item !== 'object') {
          return {
            text: item,
            value: item,
          }
        }
        return {
          text: item?.text || '',
          value: item?.value || '',
        }
      })
    },
    optionsList() {
      if (Array.isArray(this.options)) {
        const formattedFullList = this.formattedList
        if (this.searchable && this.selected.text !== '') {
          return formattedFullList
            .filter((item) =>
              item?.text
                ?.toLowerCase()
                ?.includes(this.selected?.text?.toLowerCase()),
            )
            .map((item) => {
              return {
                text: this.highlight(item.text, this.selected.text),
                value: item.value,
              }
            })
        }
        return formattedFullList
      } else if (typeof this.options === 'object') {
        return Object.entries(this.options).map(([text, value]) => ({
          text,
          value,
        }))
      } else {
        console.warn('Could not procceed options in select: ' + this.id)
        return []
      }
    },
  },
  watch: {
    value: {
      handler(newValue) {
        const selectedIndex = this.getSelectedIndex(newValue)
        if (selectedIndex > -1) {
          this.selected = { ...this.formattedList[selectedIndex] }
        }
      },
      immediate: true,
    },
    selected: {
      handler(newValue, prevValue) {
        const { value } = newValue
        const isInList = this.formattedList.find(
          (predicate) => predicate.value.toLowerCase() === value.toLowerCase(),
        )
        if (isInList) {
          this.$emit('input', value)
          this.$emit('blur', value)
          if (value !== prevValue) this.setListInvisible()
        }
      },
      deep: true,
    },
    isListVisible(newValue) {
      if (newValue === false) {
        const currentOption = this.formattedList.find(
          (predicate) => predicate?.value === this.selected.value,
        )
        if (currentOption?.text) {
          this.selected.text = currentOption.text
            .replace('<strong>', '')
            .replace('</strong>', '')
        }
      }
    },
    options(newValue, oldValue) {
      const isSame = JSON.stringify(newValue) === JSON.stringify(oldValue)

      if (!isSame) this.selected = { value: '', text: '' }
    },
  },
  mounted() {
    this.$emit('field-ready')
  },
  methods: {
    setListVisible() {
      if (this.readonly) return
      this.isListVisible = true
    },
    setListInvisible() {
      this.isListVisible = false
    },
    toggleList() {
      if (this.readonly) return
      this.isListVisible = !this.isListVisible
    },
    setSelectedOption(option) {
      this.selected = {
        value: option.value,
        text: option.text.replace('<strong>', '').replace('</strong>', ''),
      }
    },
    highlight,
    getSelectedIndex(value = this.value) {
      const lowerCasedValue = value?.toLowerCase()
      return this.options.findIndex((predicate) => {
        if (typeof predicate === 'object') {
          return (
            predicate?.selected === true ||
            lowerCasedValue === predicate?.value?.toLowerCase()
          )
        } else if (typeof predicate === 'string') {
          return lowerCasedValue === predicate?.toLowerCase()
        } else {
          return false
        }
      })
    },
  },
}
